import { useState } from 'react'
import { makeStyles } from '../../makeStyles'
import { Palette } from '../../util/palette/Palette'
import { devLog } from '../../util/utilFunctions'
import Image from 'next/image'

// SECTION: Styles
const useStyles = (palette: any) => {
	const { background9, background1 } = palette

	return makeStyles()({
		customBookCover: {
			objectFit: 'cover',
			imageRendering: '-webkit-optimize-contrast' // this used to fix blurry down scaled images on Chrome -- doesn't anymore (only an issue if img has wierd dimensions)
			// willChange: 'transform', // This and the next rule works to remove chrome blurryness (may cause issues on some browsers) but won't work with objectFit
			// transform: 'translateZ(0)'
		},
		emptyImage: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%'
		},
		_emptyImageBackground: {
			backgroundColor: background9,
			boxShadow: `0 0 20px 0 inset ${background1}`
		}
	})
}

// SECTION: Props
interface CustomBookCoverProps {
	imageWidth: number
	imageHeight: number
	customCoverUrl?: string
	customEmptyImageStyle?: string
	showBackgroundOnEmptyImage?: boolean
}

const CustomBookCover = ({
	customCoverUrl,
	customEmptyImageStyle,
	showBackgroundOnEmptyImage,
	imageWidth,
	imageHeight
}: CustomBookCoverProps) => {
	// SECTION: Hooks
	const [imageError, setImageError] = useState(false)
	const { classes, cx } = useStyles(Palette.getPalette())()

	// SECTION: Render
	const image = (
		<Image
			className={classes.customBookCover}
			src={customCoverUrl!}
			alt={''}
			width={imageWidth}
			height={imageHeight}
			onError={() => setImageError(true)}
		/>
	)
	return imageError || !customCoverUrl ? (
		<div
			className={cx(
				classes.emptyImage,
				showBackgroundOnEmptyImage && classes._emptyImageBackground,
				customEmptyImageStyle
			)}
		/>
	) : (
		image
	)
}

export default CustomBookCover
