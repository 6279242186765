import { dbLog, devLog } from '../../util/utilFunctions'
import { getDb } from './getCreateSaveCustomBookIdb'

export interface IdbCurrentSpaceObj {
	usage: number
	quota: number
}
// SECTION: Util
// Only confirmed to work on Chrome, Firefox, and Edge

export const getIdbCurrentSpaceObj = async () => {
	dbLog('idb: getIdbCurrentSpaceObj')
	if (navigator.storage && navigator.storage.estimate) {
		return (await navigator.storage.estimate()) as IdbCurrentSpaceObj
	}
	return null
}

// Will ask for permission if not previously granted/denied
// Only asks if user hasn't previously accepted (on Firefox -- Edge doenst even ask)
// If denied, browsers will start evicting data once quota is reached (rather than throwning error)
// If denied initially, this will ask again every time the function is run (at least on Firefox)
// Persistance on Chrome is decided via certain metrics (is the site bookmarked, have high site engagement, added to home screen, etc) and won't ask for
// Normally functionality seems to work fine even if permissions rejected (tested on Firefox)
export const askPermissionIdbStoragePersistant = async () => {
	dbLog('idb: askPermissionIdbStoragePersistant')
	if (navigator.storage && navigator.storage.persist) {
		return await navigator.storage.persist()
	}
}

export const getQuickConvertTextIdbExists = async (userId: string) => {
	dbLog('idb: getQuickConvertTextIdbExists')
	const result = await getCustomBookIdbExists(userId, `quick-convert-text-${userId}`)
	return result
}

export const getCustomBookIdbExists = async (userId: string, bookTitle: string) => {
	dbLog('idb: getCustomBookIdbExists')
	const dbs = await getListOfUsersBookIdbs(userId)

	for (let dbName of dbs) {
		if (dbName === bookTitle) {
			return true
		}
	}
	return false
}

// NOTE: The point of db-lookups is to track the ACTIVE custom books on the device (and get us a list). Mongo db is tracking ALL of them, so that won't work
export const getListOfUsersBookIdbs = async (userId: string) => {
	dbLog('idb: getListOfUsersBookIdbs')
	let lookupDb = await getDb('db-lookups')
	if (!lookupDb) throw Error(`getListOfUsersBookIdbs: getting idb db-lookups returned undefined`)

	const lookupStore = lookupDb.transaction('custom-book-dbs').objectStore('custom-book-dbs')

	// Get all custom book db names in browser
	const allNames = await lookupStore.getAll()

	// Filter and return only the db names tagged with the current user's id
	return allNames.filter((dbName) => {
		return dbName.includes(userId)
	})
}
