import { Palette, PaletteTheme } from '../../util/palette/Palette'
import SemipolarLoading from '../../util/react-loadingg/SemipolarLoading'
import { makeStyles } from 'makeStyles'
import { renderLog } from '../../util/utilFunctions'
import { UtilContext } from '../../contexts/UtilContext'
import { ReactElement, useContext } from 'react'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { background7, font1, loadingOverlayText, mainBackgroundColor, fullHeightMinusHeaderAndBreadcrumbs } = palette

	return makeStyles()({
		fullScreenProcessingContainer: {
			position: 'fixed',
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 100,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			backgroundColor: background7
		},
		belowBreadcrumbsProcessingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: fullHeightMinusHeaderAndBreadcrumbs
		},
		boundProcessingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%'
		},
		_noBackground: {
			backgroundColor: 'revert'
		},
		_solidMainBackground: {
			backgroundColor: mainBackgroundColor
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '7rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				padding: 0
			}
		},
		processingText: {
			fontFamily: font1,
			fontSize: 16,
			color: loadingOverlayText,
			marginTop: '0.2rem'
		}
	})
}

// SECTION: Props
export enum LoadingOverlaySize {
	FULL_SCREEN = 'FULL_SCREEN',
	BELOW_BREADCRUMBS = 'BELOW_BREADCRUMBS',
	BOUND = 'BOUND'
}

export enum LoadingIconType {
	SEMIPOLAR_LOADING = 'SEMIPOLAR_LOADING' // Using this icon for everything
}

export enum LoadingBackgroundType {
	NONE = 'NONE',
	TRANSPARENT = 'TRANSPARENT',
	SOLID_MAIN_BACKGROUND = 'SOLID_MAIN_BACKGROUND'
}

interface LoadingOverlayProps {
	loadingOverlaySize: LoadingOverlaySize
	loadingBackgroundType: LoadingBackgroundType
	loadingIconType: LoadingIconType
	loadingText: string
}

const LoadingOverlay = ({
	loadingOverlaySize,
	loadingBackgroundType,
	loadingIconType,
	loadingText
}: LoadingOverlayProps) => {
	// SECTION: Hooks

	const { isConsolePage } = useContext(UtilContext)
	const { getConsoleTheme } = useContext(UserSettingsContext)

	const { classes, cx } = useStyles(
		isConsolePage() ? Palette.getConsolePalette(getConsoleTheme()) : Palette.getPalette()
	)()

	const { loadingOverlayText } = isConsolePage() ? Palette.getConsolePalette(getConsoleTheme()) : Palette.getPalette()

	// SECTION: UI Getters
	const getSizeAndBackgroundStyles = (
		loadingOverlaySize: LoadingOverlaySize,
		loadingBackgroundType: LoadingBackgroundType
	) => {
		const sizeStyleLookup: { [backgroundStyle: string]: string } = {
			FULL_SCREEN: classes.fullScreenProcessingContainer,
			BELOW_BREADCRUMBS: classes.belowBreadcrumbsProcessingContainer,
			BOUND: classes.boundProcessingContainer
		}

		const backgroundStyleLookup: { [backgroundStyle: string]: string } = {
			NONE: classes._noBackground,
			TRANSPARENT: '', // default -- already in base style
			SOLID_MAIN_BACKGROUND: classes._solidMainBackground
		}

		return cx(sizeStyleLookup[loadingOverlaySize], backgroundStyleLookup[loadingBackgroundType])
	}

	const getLoadingIcon = (loadingIconType: LoadingIconType) => {
		const loadingIconLookup: { [loadingIconType: string]: ReactElement } = {
			SEMIPOLAR_LOADING: <SemipolarLoading style={{}} size={'large'} color={loadingOverlayText} />
		}

		return loadingIconLookup[loadingIconType]
	}

	// SECTION: Render UI
	return (
		<div className={getSizeAndBackgroundStyles(loadingOverlaySize, loadingBackgroundType)}>
			{renderLog('LOADING OVERLAY')}
			<div className={classes.contentContainer}>
				{getLoadingIcon(loadingIconType)}
				<p className={classes.processingText}>{loadingText}</p>
			</div>
		</div>
	)
}

export default LoadingOverlay
