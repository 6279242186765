import { dbLog, devLog } from '../../util/utilFunctions'
import { SimpleBookDetails } from '../../components/BookSelectPage/BookSelectPage'
import { getDb } from './getCreateSaveCustomBookIdb'
import { getListOfUsersBookIdbs } from './utilIdb'

export const getAllCustomBookDetailsFromIdb = async (userId: string) => {
	dbLog('idb: getAllCustomBookDetailsFromIdb')
	let dbNames = await getListOfUsersBookIdbs(userId)

	// Mapping over async functions runs them all asyncronously and returns an array of promises.
	const promises = dbNames.map(async (dbName: string) => {
		let db = await getDb(dbName)
		if (!db) return // Probebly rejects the promise

		let cursor = await db.transaction('book-details').objectStore('book-details').openCursor()

		const simpleBookDetails: { [key: string]: any } = {}
		while (cursor) {
			simpleBookDetails[cursor.key as string] = cursor.value
			cursor = await cursor.continue()
		}

		simpleBookDetails['customBook'] = true
		simpleBookDetails['bookTitle'] = dbName // Ensures bookTitle isn't undefined (which it might be if coming out of idb property)
		return simpleBookDetails
	})

	// NOTE: This function allows existing book details through, even if others aren't found due to missing bookDb
	// When promises resolved allSettled puts them in an array (Promise.all() would throw an error if any promises are rejected)
	const settledPromises = await Promise.allSettled(promises)

	// Unpack the value of any successful promises and replace any rejected promises with null
	let simpleBookDetails = settledPromises.map((settledPromise) => {
		if (settledPromise.status === 'fulfilled') {
			return settledPromise.value
		}
		return null
	})

	// Filter out the nulls leaving only successfully fetched custom book details
	simpleBookDetails = simpleBookDetails.filter(Boolean)

	return simpleBookDetails as SimpleBookDetails[]
}

export const getCustomBookDetailsFromIdb = async (bookTitle: string) => {
	dbLog('idb: getCustomBookDetailsFromIdb')
	return await getAllStoreKeyValuePairs(bookTitle, 'book-details')
}

const getAllStoreKeyValuePairs = async (bookTitle: string, objectStoreName: string) => {
	let db = await getDb(bookTitle)

	if (!db) throw Error(`getCustomBookDetailsFromIdb: getting idb ${bookTitle} returned undefined`)

	const store = db.transaction(objectStoreName).objectStore(objectStoreName)
	let cursor = await store.openCursor()

	const rawBook: { [key: string]: any } = {}
	while (cursor) {
		rawBook[cursor.key as string] = cursor.value
		cursor = await cursor.continue()
	}

	return rawBook
}

export const getCustomBookDetailFromIdb = async (bookTitle: string, propertyKey: string) => {
	dbLog('idb: getCustomBookDetailFromIdb')
	let db = await getDb(bookTitle)

	if (!db) throw Error(`getCustomBookDetailFromIdb: getting idb ${bookTitle} returned undefined`)

	const store = db.transaction('book-details').objectStore('book-details')
	const property = await store.get(propertyKey)

	return property
}

export const saveCustomBookDetailsToIdb = async (bookTitle: string, bookDetails: { newKey: string; newVal: any }[]) => {
	dbLog('idb: saveCustomBookDetailsToIdb')
	let db = await getDb(bookTitle)

	if (!db) throw Error(`saveCustomBookDetailsToIdb: getting idb ${bookTitle} returned undefined`)

	const customBookStore = db.transaction('book-details', 'readwrite').objectStore('book-details')

	for (const { newKey, newVal } of Object.values(bookDetails)) {
		await customBookStore.put(newVal, newKey)
	}
}
