import { memo, useContext } from 'react'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { Typography } from '@mui/material'
import { devLog, renderLog } from '../../util/utilFunctions'
import { UtilContext } from '../../contexts/UtilContext'
import Link from 'next/link'
import { makeStyles } from 'makeStyles'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { font1, link3, link1, linkHoverTransition } = palette

	return makeStyles()({
		// NOTE: Total height of this effects fullHeightMinusHeaderAndFooter and fullHeightMinusHeaderAndMobileFooter
		footerContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			flexGrow: 1,
			paddingTop: '1rem',
			paddingBottom: '0.5rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingTop: '0.5rem',
				paddingBottom: '0.2rem'
			}
		},
		_hidden: {
			visibility: 'hidden'
		},
		root: {
			display: 'flex',
			flexDirection: 'column',
			color: link3,
			paddingLeft: '2rem',
			paddingRight: '2rem',
			['@media (max-width:1279px)']: {
				paddingLeft: '1rem',
				paddingRight: '1rem'
			}
		},
		splitTextRow: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%'
		},
		text: {
			fontFamily: font1,
			fontSize: 12,
			fontWeight: 'bold'
		},
		link: {
			textDecoration: 'none',
			transition: linkHoverTransition,
			color: link3,
			'&:hover': {
				color: link1
			}
		}
	})
}

const FooterBar = memo(() => {
	// SECTION: Hooks
	const { windowWidth, isDesktopDisplay } = useContext(UtilContext)
	const { userSettingsObj } = useContext(UserSettingsContext)

	const { classes, cx } = useStyles(Palette.getPalette())()

	// SECTION: UI Getters
	const getFooterLink = (href: string, text: string, nofollow?: boolean) => (
		<Link href={href} className={classes.link} target='_blank' rel={nofollow ? 'nofollow' : undefined}>
			{text}
		</Link>
	)

	const getFooterForDesktop = () => {
		return (
			<>
				<div className={classes.splitTextRow}>
					<Typography className={classes.text}>
						Sources:&nbsp;
						{getFooterLink('https://www.gutenberg.org/', 'gutenberg.org')}
						&nbsp;+&nbsp;
						{getFooterLink('https://www.wikipedia.org/', 'wikipedia.org')}
						&nbsp;+&nbsp;
						{getFooterLink('https://artsandculture.google.com/', 'artsandculture.google.com')}
						&nbsp;+&nbsp;
						{getFooterLink('https://fonts.google.com/', 'fonts.google.com')}
					</Typography>
				</div>
				<div className={classes.splitTextRow}>
					<Typography className={classes.text}>
						{getFooterLink('https://www.flaticon.com', 'flaticon.com')}
						&nbsp;
						{getFooterLink('https://www.flaticon.com/authors/freepik', '(Freepik)')}
						&nbsp;
						{getFooterLink('https://www.flaticon.com/authors/xnimrodx', '(xnimrodx)')}
						&nbsp;+&nbsp;
						{getFooterLink('https://www.rawpixel.com/', 'rawpixel.com')}
						&nbsp;
						{getFooterLink(
							'https://www.rawpixel.com/markusspiske/showcase?sort=curated&mode=shop&page=1',
							'(Markus Spiske)'
						)}
						&nbsp;
						{getFooterLink(
							'https://www.rawpixel.com/america2019/showcase?sort=curated&mode=shop&page=1',
							'(Carol M Highsmith)'
						)}
						&nbsp;
						{getFooterLink('https://www.rawpixel.com/nasa/showcase?sort=curated&mode=shop&page=1', '(NASA)')}
					</Typography>
					<Typography className={classes.text}>
						{/* Not usering rel: nofollow for main link */}
						<Link href='mailto:contact@typelit.io' className={classes.link}>
							contact@typelit.io
						</Link>
					</Typography>
				</div>
				<div className={classes.splitTextRow}>
					<Typography className={classes.text}>
						{getFooterLink('https://unsplash.com/', 'unsplash.com')}
						&nbsp;
						{/* Person who did Bochan cover -- no longer on unsplash.com */}
						(toxinu)&nbsp;+&nbsp;
						{getFooterLink('https://www.publicdomainpictures.net/', 'publicdomainpictures.net')}
						&nbsp;
						{getFooterLink('https://www.publicdomainpictures.net/en/browse-author.php?a=142134', '(Mirek Schubert)')}
					</Typography>
					<Typography className={classes.text}>
						{getFooterLink('/policies/cookie-policy', 'Cookie Policy', true)}
						&nbsp;+&nbsp;
						{getFooterLink('/policies/privacy-policy', 'Privacy Policy', true)}
					</Typography>
				</div>
				<div className={classes.splitTextRow}>
					<Typography className={classes.text}>
						{getFooterLink('https://brailleinstitute.org/freefont', 'brailleinstitute.org')}
						&nbsp;+&nbsp;
						{getFooterLink('https://opendyslexic.org/', 'opendyslexic.org')}
						&nbsp;+&nbsp;
						{getFooterLink('https://www.tenbytwenty.com', 'tenbytwenty.com')}
					</Typography>
					<Typography className={classes.text}>
						{getFooterLink('/policies/terms-and-conditions', 'Terms and Conditions', true)}
					</Typography>
				</div>
				<div className={classes.splitTextRow}>
					<Typography className={classes.text}>
						{getFooterLink('https://fontsarena.com', 'fontsarena.com')}
						&nbsp;
						{getFooterLink('https://fontsarena.com/metropolis-by-chris-simpson/', '(Chris Simpson)')}
						&nbsp;+&nbsp;
						{getFooterLink('https://www.fontspace.com', 'fontspace.com')}
						&nbsp;
						{getFooterLink('https://www.fontspace.com/jake-luedecke-motion-and-graphic-design', '(Jake Luedecke)')}
					</Typography>
					<Typography className={classes.text}>Copyright © {new Date().getFullYear()} TypeLit Limited</Typography>
				</div>
			</>
		)
	}

	const getFooterForMobile = () => {
		return (
			<>
				<Typography className={classes.text}>
					<Link href='mailto:contact@typelit.io' className={classes.link}>
						contact@typelit.io
					</Link>
				</Typography>
				<Typography className={classes.text}>
					{getFooterLink('/policies/cookie-policy', 'Cookies', true)}
					&nbsp;+&nbsp;
					{getFooterLink('/policies/privacy-policy', 'Privacy', true)}
					&nbsp;+&nbsp;
					{getFooterLink('/policies/terms-and-conditions', 'Terms', true)}
				</Typography>
				<Typography className={classes.text}>Copyright © {new Date().getFullYear()} TypeLit Limited</Typography>
			</>
		)
	}

	const getFooterText = () => {
		return isDesktopDisplay() ? getFooterForDesktop() : getFooterForMobile()
	}

	// SECTION: Render UI
	return (
		<footer className={cx(classes.footerContainer, !windowWidth && classes._hidden)}>
			{renderLog('FOOTER BAR')}
			<div className={classes.root}>{windowWidth ? getFooterText() : ''}</div>
		</footer>
	)
})

export default FooterBar
